import React from 'react'
import NextLink from 'next/link'
import classNames from 'classnames'

import ImageAsset from '@components/image_asset'
import styles from '@components/blocks/three_columns_block.module.scss'

class ThreeColumnsBlock extends React.Component {
  handleClick = url => {
    if (url) {
      window.location = url
    }
  }

  render() {
    const fields = this.props.fields
    const classes = [styles.threeColumnsBlock, 'threeColumnsBlock']
    if (fields.layout) {
      classes.push(styles[`threeColumnsBlock--${fields.layout.replace(/\s/g, '')}`])
    }

    return (
      <div className={classes.join(' ')}>
        <ColumnBlock
          image={fields.leftImage}
          header={fields.leftImageHeader}
          imageText={fields.leftImageText}
          linkUrl={fields.leftImageLink}
          linkText={fields.leftImageLinkText}
          onClick={this.handleClick}
        />
        <ColumnBlock
          image={fields.middleImage}
          header={fields.middleImageHeader}
          imageText={fields.middleImageText}
          linkUrl={fields.middleImageLink}
          linkText={fields.middleImageLinkText}
          onClick={this.handleClick}
        />
        {fields.middleImage2 && (
          <ColumnBlock
            image={fields.middleImage2}
            header={fields.middleImageHeader2}
            imageText={fields.middleImageText2}
            linkUrl={fields.middleImageLink2}
            linkText={fields.middleImageLinkText2}
            onClick={this.handleClick}
          />
        )}
        <ColumnBlock
          image={fields.rightImage}
          header={fields.rightImageHeader}
          imageText={fields.rightImageText}
          linkUrl={fields.rightImageLink}
          linkText={fields.rightImageLinkText}
          onClick={this.handleClick}
        />
      </div>
    )
  }
}

class ColumnBlock extends React.PureComponent {
  handleClick = () => {
    this.props.onClick(this.props.linkUrl)
  }

  render() {
    const { image, imageText, header, linkUrl, linkText } = this.props
    const classes = [styles.threeColumnsBlock__col]
    if (linkUrl) {
      classes.push(styles['threeColumnsBlock__col--clickable'])
    }

    return (
      <div className={classes.join(' ')}>
        <ImageAsset
          className={styles.threeColumnsBlock__image}
          asset={image}
          width="300"
          onClick={this.handleClick}
        />
        <div className={styles.threeColumnsBlock__text} onClick={this.handleClick}>
          {header && <div className={styles.threeColumnsBlock__header}>{header}</div>}
          <div className={classNames(styles.threeColumnsBlock__title, 'threeColumnsBlock__title')}>
            {imageText}
          </div>
          <div className={styles.threeColumnsBlock__link}>
            {linkUrl && <NextLink href={linkUrl}>{linkText}</NextLink>}
          </div>
        </div>
      </div>
    )
  }
}

export default ThreeColumnsBlock
